var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.params.venueId && !_vm.dataLoaded)?_c('PageLoadingBar'):_vm._e(),(!_vm.$route.params.venueId || _vm.dataLoaded)?_c('div',{staticClass:"space-facilities"},[_c('h1',[_vm._v(_vm._s(_vm.$t('space.facilities.label_header')))]),_c('form',{staticClass:"space-facilities-form",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"internet-facility form-input"},[_vm._m(0),_c('InfoBlock',[_vm._v(" "+_vm._s(_vm.$t('space.facilities.label_info_wifi'))+" ")]),_c('div',{staticClass:"items-form"},[_c('WMInput',{attrs:{"id":"wifi","data-gtm":"space_facilities_form_wifi_name_input","inline":true,"required":false,"placeholder":_vm.$t('space.facilities.input_placeholder_wifi_name'),"error":_vm.form.errors('wifi')},model:{value:(_vm.form.wifi),callback:function ($$v) {_vm.$set(_vm.form, "wifi", $$v)},expression:"form.wifi"}}),_c('WMInput',{attrs:{"data-gtm":"space_facilities_form_wifi_pass_input","inline":true,"placeholder":_vm.$t(
                'space.facilities.input_placeholder_wifi_password'
              )},model:{value:(_vm.form.wifiPassword),callback:function ($$v) {_vm.$set(_vm.form, "wifiPassword", $$v)},expression:"form.wifiPassword"}})],1)],1),_c('div',{staticClass:"facilities-list form-input"},[_c('div',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('space.facilities.label_facilities_header'))+" ")]),_vm._l((_vm.form.facilities),function(facility,index){return _c('FormServiceItem',{key:index,staticClass:"default-item",attrs:{"item":facility,"locales":'filter.facility',"demand-toggle":""},on:{"onInput":_vm.changeSelectedStatus}},[_c('transition',{attrs:{"name":"slider"}},[(facility.onDemand && facility.selected)?_c('div',{staticClass:"custom-form"},[_c('PriceInput',{attrs:{"vat":facility.vat,"service":"","advertised":"","max":999.99},model:{value:(facility.price),callback:function ($$v) {_vm.$set(facility, "price", $$v)},expression:"facility.price"}}),_c('div',{staticClass:"toggle-content"},[_c('div',{staticClass:"order-type-label"},[_vm._v(" "+_vm._s(_vm.$t( ("filter.facility.order_type." + (facility.orderType.toLowerCase())) ).toUpperCase())+" ")]),_c('div',{staticStyle:{"margin":"15px 0 0 15px"}},[_c('Popover',{attrs:{"offset":[135, -50]}},[_c('div',[_c('i',{staticClass:"fa-solid fa-circle-question fa-xs"})]),_c('div',{staticClass:"popover-content",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.$t( ("inventory_management.modal.order_type." + (facility.orderType.toLowerCase()) + ".description") ))+" ")])])],1)]),_c('FormSelect',{attrs:{"id":("vatRate-" + (facility.id)),"placeholder":_vm.$t(
                    'onboarding.organisation_details.input_placeholder_vat_rate'
                  ),"options":_vm.vatRates,"error":_vm.getVatError(facility),"inline":"","required":""},model:{value:(facility.vat),callback:function ($$v) {_vm.$set(facility, "vat", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"facility.vat"}}),_c('WMInput',{attrs:{"inline":true,"placeholder":_vm.$t(
                    'space.facilities.input_placeholder_on_demand_facility_description'
                  )},model:{value:(facility.description),callback:function ($$v) {_vm.$set(facility, "description", $$v)},expression:"facility.description"}}),(facility.inventoryAllowed)?_c('FormVerticalRadio',{attrs:{"form":facility,"limit":'maxInventory',"title":_vm.$t(
                    'onboarding.venue.facilities.label_manage_inventory_title'
                  ),"first-label":_vm.$t(
                    'onboarding.venue.facilities.label_manage_inventory_first'
                  ),"second-label":_vm.$t(
                    'onboarding.venue.facilities.label_manage_inventory_second'
                  ),"has-incremented-input":true},on:{"input":function($event){return _vm.updateFacilityLimit(facility, $event)},"updatedLimit":function (val) { return (facility.maxInventory = val); }},model:{value:(facility.selectedLimitOption),callback:function ($$v) {_vm.$set(facility, "selectedLimitOption", $$v)},expression:"facility.selectedLimitOption"}}):_vm._e()],1):_vm._e()])],1)})],2),(!_vm.$route.params.spaceId)?_c('WMButton',{attrs:{"data-gtm":"space_facilities_form_continue_button","disabled":!_vm.canContinue,"icon-class-name":("" + (_vm.canContinue ? 'fa-solid fa-arrow-right fa-lg' : '')),"straight":""},on:{"click":_vm.mainAction}},[_c('span',{style:(("" + (_vm.canContinue ? 'margin-left: 5px' : '')))},[_vm._v(" "+_vm._s(_vm.$t( _vm.canContinue ? 'space.details.button_label_primary' : 'space.details.button_label_primary_not_ready' ))+" ")])]):_vm._e()],1)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label"},[_c('div',{staticClass:"label"},[_vm._v("Wi-Fi")])])}]

export { render, staticRenderFns }